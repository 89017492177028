<section id="safe-description">
    <div class="benefits">
        <div class="wrapper-container">
            <div class="container">
                <div class="safe auto">
                    <h2 class="safe-title" [innerHTML]="data.text | htmlParse"></h2>
                    <div class="row" *ngIf="!data.product">
                        <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let card of data.cards">
                            <app-card classCustom="{{ classCustom }}" [data]="card" [legalText]="data.legal_text"></app-card>
                        </div>
                    </div>
                    <div *ngIf="data.product && data.product === 'residencia'">
                        <div class="row justify-content-center" *ngFor="let card of data.cards">
                            <div class="col-6 col-md-3 mb-4 mx-auto mx-md-0" *ngFor="let line of card">
                                <app-card classCustom="{{ classCustom }}" [data]="line" [legalText]="data.legal_text"></app-card>
                            </div>
                        </div>
                    </div>

                    <p class="safe-text" *ngIf="!data.footer_text">
                        *Algumas coberturas e serviços possuem restrições e limites diferenciados. Para saber todos os detalhes e conhecer a lista completa consulte o Manual do Segurado/Condições Gerais.
                    </p>

                    <div class="safe-text-div" *ngIf="data.footer_text" [innerHTML]="data.footer_text | htmlParse"></div>
                </div>
            </div>
        </div>
    </div>
</section>
